// Setup firebase
var firebase = require('firebase/app');
const fetchConfig = require('./fetch-config');
require('firebase/auth');
require('firebase/database');
require('firebase/storage');


var config = {
  apiKey: 'AIzaSyDgLIe7Fi0eWJlDz3mzcSh6rvyefQaLgMM',
  authDomain: 'env3d-blockly-ca.firebaseapp.com',
  databaseURL: 'https://env3d-blockly-ca.firebaseio.com',
  projectId: 'env3d-blockly-ca',
  storageBucket: 'env3d-blockly-ca.appspot.com',
  messagingSenderId: '846534956161',
  appId: '1:846534956161:web:cf160c1097e7419c566980',
};
window['myLocation'] = 'https://env3d-blockly-ca.firebaseio.com';

firebase.initializeApp(config);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// make firebase available in the global namespace so index can access it
// @todo: fix this by moving all JS into src
window['firebase'] = firebase;
window['database'] = firebase.database();

// window["cloudURL"] = "http://api.myc3d.test";
window['mainApp'] = window.location.host.includes('myc3d.test')
  ? 'http://canada.myc3d.test'
  : 'https://canada.c3d.io';
window['cloudURL'] = window.location.host.includes('myc3d.test')
  ? 'http://api.myc3d.test'
  : 'https://api.c3d.io';

// Some utility functions
window['getQueryVariable'] = function (variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0;i < vars.length;i++) {
    var pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};

// wrap code around an async
window['asyncFunc'] = function (code, duration) {
  return new Promise(function (resolve, reject) {
    code();
    var interval = setInterval(() => {
      code();
    }, 32);
    setTimeout(() => {
      clearInterval(interval);
      resolve();
    }, duration);
  });
};

window['copyToClipboard'] = function (text) {
  if (!navigator.clipboard) {
    alert(`Please copy the link ${text} and paste it in c3d.io`);
    return;
  }
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alert('text copied to clipboard');
    })
    .catch((e) => {
      console.log('error copying to clipboard', e);
    });
};

window['moduleSteps'] = {
  module1: 16,
  module2: 13,
  module3: 14,
  module4: 9,
  module5: 21,
  module6: 11,
  module7: 15,
  module8: 7,
  module9: 17,
  module10: 20,
  module11: 7,
};

window['getCookie'] = (name) => {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
};

require("./fetch-config")

fetchConfig().then(data => {

  window["classroomOrigin"] = data
})

window["checkVariable"] = () => {
  return new Promise((res) => {
    (function waitForConfig() {
      console.log("checking")
      if (window.hasOwnProperty("classroomOrigin")) return res();
      setTimeout(waitForConfig, 30);
    })();
  })

}