let AbstractComponent = require('../AbstractComponent.js');
const { createElementWithAttribute } = require('../../utils/functions');

class AddClass extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = elem;

    this.menus = [];
    this.error = false;
    this.init();
  }

  init() {
    this.createAddClassForm();
    this.createClassListContainer();
  }

  //Init add class form
  createAddClassForm() {

    const attHeader = {
      class: 'manage-input-title',
      for: 'class-name',
    };
    
    const attInstruction = {
      style: 'display: block;',
    };
    
    const attInput = {
      type: 'text',
      class: 'form-control',
      name: 'class-name',
      id: 'input-class-name',
    };
    
    const attButton = {
      id: 'add-class-btn',
      title: 'button',
    };
    
    const errDiv = {
      id: 'add-classes-message',
      class: 'text-red'
    };
    
    const classTitle = createElementWithAttribute('h2', attHeader);
    classTitle.innerHTML = 'Create a New Class';
    
    const instruction = createElementWithAttribute('small', attInstruction);
    instruction.innerHTML = 'Enter the class name';
    
    this.classInput = createElementWithAttribute('input', attInput);
    
    const classButton = createElementWithAttribute('button', attButton);
    classButton.innerHTML = 'Create';

    this.errDiv = createElementWithAttribute('div', errDiv);
      

    let addClassForm = document.createElement('form');
    addClassForm.addEventListener('submit', (e) => {
      e.preventDefault();
    });
    addClassForm.className = 'add-class-container';

    addClassForm.appendChild(classTitle);
    addClassForm.appendChild(instruction);
    addClassForm.appendChild(this.classInput);
    addClassForm.appendChild(classButton);
    addClassForm.appendChild(this.errDiv);

    this.parent.appendChild(addClassForm);

    classButton.addEventListener('click', () => {
      this.addNewClasses();
    });
  }

  //Add new classes into firebase
  addNewClasses() {

    let userId = firebase.auth().currentUser.uid;

    //Valid user, create new class
    if (userId) {
      let msg = document.getElementById('add-classes-message');
      msg.classList.remove('text-green');
      let inputClassName = document
        .getElementById('input-class-name')
        .value.trim();
      
      //check if there are already classes for the current account
      database
        .ref('/profiles/' + userId)
        .once('value')
        .then((userInfo) => {
          //find out how many classes
          let classesLength = 0;
          Object.entries(userInfo.val()).forEach(entry => {
            if (entry[0] === 'classes') {
              classesLength = Object.entries(entry[1]).length;
            }
          })

          //if the user has classes, test for the hide flag
          if (classesLength > 0){

            let classInfo = Object.entries(userInfo.val().classes);        
            classesLength = 0;
  
            // test for 'deleted' classes (hidden from frontend)
            // do not consider for Class List or Class max limit those with attribute 'hide'
            for (let entry of classInfo) {
              if (!entry[1].hide){
                classesLength++;
              }
            }
          }
          return classesLength;
        })
        .then((classesLength)=> {

          //define the number of classes permitted
          //unlimited classes
          let maxClasses = 0;

          //if the config.json file has the maxClasses attribute, set maxClasses to the number defined by the attribute
          if (classroomOrigin.maxClasses) {
            maxClasses = classroomOrigin.maxClasses;
          //if the config.json file doesn't have the maxClasses attribute and is a free license, set it to 1
          } else if (classroomOrigin.origin === 'classroom') {
            maxClasses = 1;
          }

          if (inputClassName && inputClassName.length > 1) {
            //test if a new class exceeds the limit
            if (maxClasses > 0 && classesLength >= maxClasses){
              msg.innerHTML = `The maximum number of classes is ${maxClasses}. <a href="mailto:info@c3d.io">Upgrade</a> to unlock more classes.`;
              msg.classList.add('text-red');
            //it it is unlimitted or it does not exceed the limit, add a class
            } else {
              let newClasses = database
                .ref('/profiles/' + userId + '/classes')
                .push();
              newClasses.set(
                {
                  name: inputClassName,
                },
                (error) => {
                  if (error) {
                    msg.innerText = error;
                  } else {
                    msg.innerText =
                      'Successfully created new class ' + inputClassName;
                    msg.classList.add('text-green');
                    document.getElementById('input-class-name').value = '';
                    //Update class list in Add Student & Profiles submenu & Progress view

                    const selectClassContainerProfile = document.getElementById(
                      'form-select-class-name-profile'
                    );
                    const selectClassContainerProgress = document.getElementById(
                      'progress-select-class-name'
                    );
                    const selectAssignment = document.getElementById(
                      'assignment-select-class-name'
                    );
                    const selectAssignmentProgress = document.getElementById(
                      'assignment-progress-select-class-name'
                    );
                      
                    database
                      .ref('/profiles/' + userId + '/classes')
                      .once('value')
                      .then((snapshot) => {
                        let classInfo = [];        

                        // test for 'deleted' classes (hidden from frontend)
                        // do not consider for Class List or Class max limit those with attribute 'hide'
                        for (let entry of Object.entries(snapshot.val())) {
                          if (!entry[1].hide){
                            classInfo.push(entry);
                          }
                        }

                        //Output HTML element for select list
                        let selectOptions = '';

                        const latestClassId = classInfo.reverse()[0][0];

                        //classes in reverse order, latest on top
                        classInfo.reverse().forEach((singleClass) => {
                          let classId = singleClass[0];
                          let className = singleClass[1].name;
                          selectOptions += `<option ${latestClassId === classId ? "selected" : ""} value=${classId} >${className}</option>`;
                        });
                        
                        window.windowClassId = latestClassId;
                        localStorage.setItem("classId", latestClassId)

                        //Insert all the class options in different view selector
                        selectClassContainerProfile.innerHTML = selectOptions;
                        selectClassContainerProgress.innerHTML = selectOptions;
                        selectAssignment.innerHTML = selectOptions;
                        selectAssignmentProgress.innerHTML = selectOptions;

                        location.reload();

                      });
                    //update class list
                    this.getClassList();
                  }
                }
              );
            }
          } else {
            msg.innerText = 'Please input valid class name.';
          }
        });
    } else {
      alert('Invalid User, please login.');
    }
  }

  //Init add class form
  createClassListContainer() {

    const classListTitle = createElementWithAttribute('h3', {
      class: 'manage-class-list-title',
    })
    classListTitle.innerHTML = 'Classes list';
    this.parent.appendChild(classListTitle);

    const classListContainer = createElementWithAttribute('div', {
      id: 'class-list-container',
    });

    const classList = createElementWithAttribute('ul', {
      id: 'class-list',
      class: 'list-group',
    });

    classListContainer.appendChild(classList);
    this.parent.appendChild(classListContainer);

    const noClassInListMessage = createElementWithAttribute('p', {
      class: 'no-class-list-message hide',
    });
    noClassInListMessage.innerText = 'This account have no classes.';
    this.parent.appendChild(noClassInListMessage);
    
    this.getClassList();
  }

  //fetch classes
  getClassList() {
    let userId = firebase.auth().currentUser.uid;
    let that = this;

    //Fetch Class list from firebase
    database
      .ref(`/profiles/${userId}/classes/`)
      .once('value')
      .then(function (snapshot) {

        const classList = document.getElementById('class-list');

        classList.innerHTML = '';

        // Code to make visible hidden classes
        // To show the hidden classes, comment the code from 'let classInfo = [];' (1 line)
        // to the end of the for loop that tests for the hide flag immediately below (5 lines)
        // Then, uncomment the the statement below 'let classInfo = Object.entries(snapshot.val());'
        
        // let classInfo = Object.entries(snapshot.val());

        let classInfo = [];

          // test for 'deleted' classes (hidden from frontend)
          // do not consider for Classes List or Classes max limit those with attribute 'hide')
        for (let entry of Object.entries(snapshot.val())) {
          if (!entry[1].hide){
            classInfo.push(entry);
          }
        }

        if (classInfo.length > 0) {
          
          //Save classes to the current classes list fetched
          document
            .querySelector('.no-class-list-message')
            .classList.add('hide');

          let classes = [];

          classInfo.reverse().forEach((singleClass) => {
          
            const classId = singleClass[0];
            const className = singleClass[1].name; 

            const p = createElementWithAttribute('li', {
              class: 'list-group-item single-class',
                'class-id': classId,
            });
    
            const classInfo = createElementWithAttribute('div', {
              class: 'class-info',
            });
            classInfo.innerHTML = `<span class="class-name">${className}</span>`;

            const deleteBtn = createElementWithAttribute('button', {
              class: 'delete-class-btn',
              'class-id': classId,
              'class-name': className,
            });

            deleteBtn.innerText = 'Delete';
            deleteBtn.addEventListener('click',  (e) => {
              const confirming = confirm(`This action cannot be undone. \nAre you sure you want to delete ${className}?`);
              if (confirming) {
                that.deleteClass(classId);
              } 
            });
          
            const btnContainer = createElementWithAttribute('span', {
              class: 'btn-container',
            });

            btnContainer.appendChild(deleteBtn);
            classInfo.appendChild(btnContainer);
            p.appendChild(classInfo);
            classList.appendChild(p);
          });

        } else {
          document
            .querySelector('.no-class-list-message')
            .classList.remove('hide');
        }
      });
  }

  //hide assignment from frontend
  deleteClass(classId){

    let userId = firebase.auth().currentUser.uid;

    //Valid user, delete class
    if (userId) {
      
      // code to really delete a class from the database
      // database.ref(`/profiles/${userId}/classes/${classId}`)
      // .remove()
      // this.getClassList()

      //add to database a boolean field 'hide' with value 'true'
      //Update class information, add 'hide' flag
      database.ref(`/profiles/${userId}/classes/${classId}`).update({
        hide: true
      })
      .then(success => {
        alert(`The class was deleted.`);
        this.getClassList();
      })
      .catch(error => {
        alert(`An error occurred and the class could not be deleted.`);
      });

      //Update class list in Add Student & Profiles submenu & Progress view

      const selectClassContainerProfile = document.getElementById(
        'form-select-class-name-profile'
      );
      const selectClassContainerProgress = document.getElementById(
        'progress-select-class-name'
      );
      const selectAssignment = document.getElementById(
        'assignment-select-class-name'
      );
      const selectAssignmentProgress = document.getElementById(
        'assignment-progress-select-class-name'
      );

      database
      .ref('/profiles/' + userId + '/classes')
      .once('value')
      .then((snapshot) => {
        let classInfo = [];        

        // test for 'deleted' classes (hidden from frontend)
        // do not consider for Class List or Class max limit those with attribute 'hide'
        for (let entry of Object.entries(snapshot.val())) {
          if (!entry[1].hide){
            classInfo.push(entry);
          }
        }

        //Output HTML element for select list
        let selectOptions = '';

        if (classInfo.length > 0){

          const latestClassId = classInfo.reverse()[0][0];

          //classes in reverse order, latest on top
          classInfo.reverse().forEach((singleClass) => {
            let classId = singleClass[0];
            let className = singleClass[1].name;
            selectOptions += `<option ${latestClassId === classId ? "selected" : ""} value=${classId} >${className}</option>`;
          });
          
          window.windowClassId = latestClassId;
          localStorage.setItem("classId", latestClassId);

        } else {
          selectOptions = `<option>No class found, please create a class in Manage menu</option>`;
        }

        //Insert all the class options in different view selector
        selectClassContainerProfile.innerHTML = selectOptions;
        selectClassContainerProgress.innerHTML = selectOptions;
        selectAssignment.innerHTML = selectOptions;
        selectAssignmentProgress.innerHTML = selectOptions;

        location.reload();

      });
    } else {
      alert('Invalid User, please login.');
    }

  }
}

module.exports = AddClass;
