let AbstractComponent = require("../AbstractComponent.js");
const axios = require("axios");

class Support extends AbstractComponent {
    constructor(elem) {
      super(elem);
  
      this.parent = elem;
  
      this.menus = [];
      this.error = false;
      this.init();
    }
  
    init() {
        
      let supportRootContainer = document.createElement('div');
      supportRootContainer.className = "container";

      this.parent.appendChild(supportRootContainer);

      //Title part  
      let supportTitle = document.createElement("h1");
      supportTitle.className = "title";
      supportTitle.innerText = "We are here to help!";

      let subtitle = document.createElement("p");
      subtitle.className="subtitle";
      subtitle.innerText = "Tell us how we can help."
      
      let tittleContainer = document.createElement("div");
      tittleContainer.className = "title-container";
      tittleContainer.appendChild(supportTitle);
      tittleContainer.appendChild(subtitle)

      supportRootContainer.appendChild(tittleContainer);
        
      //Form part
      let formContent = `<div class="form-group">
            <label for="first-name">First name</label>
            <input class="form-control" type="text" name="first-name" id="form-input-first-name">
        </div>
        <div class="form-group">
            <label for="last-name">Last name</label>
            <input class="form-control" type="text" name="last-name" id="form-input-last-name">
        </div>
        <div class="form-group">
            <label for="email">Email</label>
            <input class="form-control" type="email" name="email" id="form-input-email">
        </div>
        <div class="form-group">
            <label for="message">Briefly tell us about your concerns or questions. How can we help you?</label>
            <textarea class="form-control" name="message" id="form-message" rows="3"></textarea>
        </div>
        <p id="support-error-msg" class="text-red"></p>
        <button id="support-form-btn" class="form-submit" type="submit">Send</button>
        `;

        let supportForm = document.createElement('form');
        supportForm.innerHTML = formContent;
        // supportForm.action="#";
        supportForm.method="POST";
        supportForm.setAttribute("data-netlify", "true");

        //Handle form submit
        supportForm.addEventListener("submit",(e)=>{
            e.preventDefault();
            //Get user input
            let fName = document.getElementById("form-input-first-name");
            let lName = document.getElementById("form-input-last-name");
            let email = document.getElementById("form-input-email");
            let mailContent = document.getElementById("form-message");
            let errorMsg = document.getElementById("support-error-msg");
            errorMsg.classList.remove("text-green");

            let submitBtn = document.getElementById("support-form-btn");
            submitBtn.disabled = true;
            submitBtn.classList.add("gray-btn");
            let contactInfo = {};
            if(fName.value.trim().length>0&&lName.value.trim().length>0
            &&email.value.trim().length>0&&mailContent.value.trim().length>0)
            {
                contactInfo = {
                   name: fName.value + " " + lName.value,
                   email: email.value,
                   content: mailContent.value}
                
                errorMsg.innerText = 'Sending Message......';
                //Send message to the backend
                this.sendMail(contactInfo)
            }
            else{
                errorMsg.innerText = 'Please enter valid message.';
                submitBtn.disabled = false;
                submitBtn.classList.remove("gray-btn");
            }

            
            
            
        })


        supportRootContainer.appendChild(supportForm);
  
    }

    //Send email via express backend on netlify
    sendMail(contactInfo)
    { axios
        .post('https://c3d-mailer-server.netlify.app/.netlify/functions/server/c3d-contact-teacher', contactInfo)
        .then(function (response) {

            if (response.status === 200 && response.data.isSent === true) {
            
                //Empty all form input
                let fName = document.getElementById("form-input-first-name");
                let lName = document.getElementById("form-input-last-name");
                let email = document.getElementById("form-input-email");
                let mailContent = document.getElementById("form-message");
                let errorMsg = document.getElementById("support-error-msg");

                fName.value = "";
                lName.value = "";
                email.value = "";
                mailContent.value = "";

                errorMsg.innerText = "Thank you for contacting us. We will get back to you as soon as possible. ";
                errorMsg.classList.add("text-green");
                    
                let submitBtn = document.getElementById("support-form-btn");
                submitBtn.disabled = false;
                submitBtn.classList.remove("gray-btn");
               
            } else {
                let submitBtn = document.getElementById("support-form-btn");
                submitBtn.disabled = false;
                submitBtn.classList.remove("gray-btn");
                errorMsg.innerText = "We couldn't get your messages, please wait for a moment.";
               
            }

        })
        .catch(function (error) {
            console.log(error);
            let errorMsg = document.getElementById("support-error-msg");
            errorMsg.innerText = "We couldn't get your messages, please wait for a moment.";
            let submitBtn = document.getElementById("support-form-btn");
            submitBtn.disabled = false;
            submitBtn.classList.remove("gray-btn");
        });}
  
  }
  
  module.exports = Support;
  