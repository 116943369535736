const { exec, init } = require('pell');

const Editor = (element, initText) => {
  const editor = init({
    element: element,
    onChange: html => {
      // document.getElementById('html-output').textContent = html
    },
    defaultParagraphSeparator: 'p',
    styleWithCSS: true,
    actions: [
      'bold',
      'underline',
      {
        name: 'italic',
        result: () => exec('italic')
      },
      {
        name: 'backColor',
        icon: '<div style="background-color:pink;">A</div>',
        title: 'Highlight Color',
        result: () => exec('backColor', 'pink')
      },
      // {
      //   name: 'image',
      //   result: () => {
      //     const url = window.prompt('Enter the image URL')
      //     if (url) exec('insertImage', url)
      //   }
      // },
      {
        name: 'link',
        result: () => {
          const url = window.prompt('Enter the link URL')
          if (url){
            //https://developer.mozilla.org/en-US/docs/Web/API/Selection/anchorNode
            //https://developer.mozilla.org/en-US/docs/Web/API/Node
            //select range of text the user is interacting with 
            let userSelection = document.getSelection();
            //create link in the range of text the user is interacting with (return a boolean)
            exec('createLink', url)
            //select the node element (#text) the user is interacting with
            //and add a 'target' attribute with value '_blank' to its parent node (<a> tag)
            userSelection.anchorNode.parentNode.target = '_blank';
          }
        }
      }
    ],
    classes: {
      actionbar: 'pell-actionbar-custom-name',
      button: 'pell-button-custom-name',
      content: 'pell-content-custom-name',
      selected: 'pell-button-selected-custom-name'
    }
  })

  editor.content.innerHTML = initText
  return editor
}
module.exports = Editor