let AbstractComponent = require('../AbstractComponent.js');
let signIn = require('./login.js');
let signInWithGoogle = signIn.signInWithGoogle;
let signInWithMail = signIn.signInWithMail;

let signUp = require('./signup.js');
let signUpWithMail = signUp.signUpWithMail;

const {
  createElementWithAttribute,
} = require('../../utils/functions');

class Auth extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.init(elem);
    
    // End of contructor
  }

  init(elem) {
    this.isLogin = elem.id === 'login' ? true : false;
    this.form = document.createElement('form');
    this.label = [];
    this.input = [];
    this.button = [];

    this.header = document.createElement('h2');
    this.header.innerHTML = this.isLogin ? 'Login' : 'Sign up';
    this.form.appendChild(this.header);

    // Can add more fields for signup if needed
    let form
    if (this.isLogin) {
      if (classroomOrigin.account.length > 0) {
        form = ['Password']
      } else {
        form = ['Email', 'Password']
      }
    } else {
      form = ['Email', 'Password', 'Repeat Password']
    }

    form.forEach((label, index) => {
      this.label[index] = document.createElement('label');
      this.label[index].innerHTML = label;

      this.input[index] = document.createElement('input');
      this.input[index].type = label.includes('Password') ? 'password' : 'text';

      this.form.appendChild(this.label[index]);
      this.form.appendChild(this.input[index]);
      this.form.appendChild(document.createElement('br'));
    });

    if (!this.isLogin) {
      // Check box for term of service and privacy policy
      let checkboxDiv = document.createElement('div');
      checkboxDiv.className = 'checkbox';

      this.checkbox = document.createElement('input');

      let checkboxLabel = document.createElement('label');
      this.checkbox.setAttribute('type', 'checkbox');
      this.checkbox.setAttribute('name', 'checkbox');

      checkboxLabel.innerHTML = `By clicking the button below, you agree to 
        <a href="https://canada.c3d.io/terms-of-service.html" target="_blank">Terms of Service</a> and 
        <a href="https://www.c3d.io/codeca-privacy-policy" target="_blank">Privacy Policy</a>`;
      checkboxLabel.setAttribute('for', 'checkbox');

      checkboxDiv.appendChild(this.checkbox);
      checkboxDiv.appendChild(checkboxLabel);
      this.form.appendChild(checkboxDiv);
    }

    let loginButtons = classroomOrigin.account.length > 0 ? ['email'] : ['email', 'Google']
    // use loop if there is more login methods in the future
    loginButtons.forEach((el, index) => {
      this.button[index] = document.createElement('button');
      switch (index) {
        case (index = 0):
          if (this.isLogin === true) {
            this.button[index].innerHTML = 'Login';
          } else {
            this.button[index].innerHTML = 'Sign up';
          }
          break;
        case (index = 1):
          this.button[index].innerHTML = `Login with ${el}`;
          break;
        default:
          break;
      }
      this.button[index].addEventListener('click', (evt) => {
        this.handleButton(index, evt);
      });
      this.form.appendChild(this.button[index]);
    });
    this.form.appendChild(document.createElement('br'));
    this.switch = document.createElement('a');

    if (this.isLogin) {
      if (!classroomOrigin.account.length > 0) {
        this.form.appendChild(
          document.createTextNode("Don't have an account? "))

        this.switch.innerHTML = 'Signup here!';
        this.switch.href = `${window.location.origin}/signup.html`;
      }
    } else {
      this.form.appendChild(
        document.createTextNode('Already has an account? ')
      );
      this.switch.innerHTML = 'Login here!';
      this.switch.href = `${window.location.origin}/login.html`;

    }


    this.switch.innerHTML.length > 0 && this.form.appendChild(this.switch);

    this.form.appendChild(document.createElement('br'));
    this.forget = document.createElement('a');
    this.forget.innerHTML = 'Forget password?';
    this.forget.href = `${window.location.origin}/forget.html`;
    classroomOrigin.account.length > 0 ? "" : this.form.appendChild(this.forget);

    this.elem.appendChild(this.form);

    this.handleSessionLogin();
  }

  handleSessionLogin() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (!classroomOrigin.isLocal) {
          //login in backend-session, then a session cookie will be sent back
          this.sessionLogin(user).then((status) => {
            if (status === 200) {
              if (user.emailVerified) {
                localStorage.setItem('uid', user.uid);
                //Verified User, go to entrance.html to select role
                window.location.href = `${window.location.origin}/entrance.html`;
              } else {
                window.location.href = `${window.location.origin}/verification.html`;
              }
            }
          });
        } else {
          if (user.emailVerified) {
            localStorage.setItem('uid', user.uid);
            //Verified User, go to entrance.html to select role
            window.location.href = `${window.location.origin}/entrance.html`;
          } else {
            window.location.href = `${window.location.origin}/verification.html`;
          }
        }

      } else {
        this.init();
      }
    });
  }

  handleButton(index, e) {
    e.preventDefault();

    switch (index) {
      case (index = 0):
        if (this.isLogin) {
          if (classroomOrigin.account.length > 0) {
            signInWithMail(classroomOrigin.account, this.input[0].value);
          } else {
            signInWithMail(this.input[0].value, this.input[1].value);
          }
        } else {
          if (this.input[1].value === this.input[2].value) {
            if (this.checkbox.checked) {
              signUpWithMail(this.input[0].value, this.input[1].value);
            } else {
              alert(
                'Please check our Terms of Service and Privacy Policy to process.'
              );
            }
          } else {
            alert('Password does not match.');
          }
        }
        break;
      case (index = 1):
        if (this.isLogin) {
          signInWithGoogle();
        } else {
          if (this.checkbox.checked) {
            signInWithGoogle();
          } else {
            alert(
              'Please check our Terms of Service and Privacy Policy to process.'
            );
          }
        }
        break;
      default:
        break;
    }
  }

  autoSignin() {
    let searchParams = new URLSearchParams(window.location.search);

    const pwd = searchParams.get("pwd");

    // const key = process.env.PWD_EN_KEY;
    const key = "motcaikeyratladaivaxamxicandenhonmuoisaukitu";

    if (pwd) {
      // Create an encryptor:
      const encryptor = require('simple-encryptor')(key);

      const decrypted = encryptor.decrypt(pwd);

      signInWithMail(classroomOrigin.account, decrypted);
    }

  }

  sessionLogin(user) {
    return user.getIdToken().then(function (idToken) {
      document.body.classList.add("loading");

      let rightDiv = document.querySelector(".right");

      let loading = createElementWithAttribute("div", {
        class: "spinner-border text-dark",
        role: "status"
      });

      rightDiv.appendChild(loading);

      let span = createElementWithAttribute("span", {

      });

      span.innerHTML = "Signing in. Please wait a moment...";

      rightDiv.appendChild(span);

      return fetch(`${cloudURL}/sessionLogin`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Access-Control-Allow-Origin': cloudURL,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }).then((result) => {
        console.log('here');
        return result.status;
      });
    });
  }
}
module.exports = Auth;
