let AbstractComponent = require("../AbstractComponent.js");


class ManageMenu extends AbstractComponent {
    constructor(elem) {
      super(elem);
  
      this.parent = elem;
  
      this.menus = [];
      this.error = false;
      this.init();
    }
  
    init() {
      
      this.createSubmenu();

      this.insertMenuItem('Classes','manage-add-class',true);
      // this.insertMenuItem('Add Student','manage-add-student',false);
      this.insertMenuItem('Profiles','manage-profiles',false);

      this.insertMenuItem('Setting','manage-setting',false);

    }

    //Create Submenu
    createSubmenu(){
      let submenuContainer = document.createElement('nav');
      submenuContainer.className = "navigation-submenu active-submenu";
      submenuContainer.id = 'manage-submenu';

      let submenuList = document.createElement("ul");
      submenuList.className = "submenu-list";
      submenuList.id = "manage-submenu-list";
      let drawerLogo = document.createElement("img");
      drawerLogo.src="./media/c3d_logo_name.png";
      drawerLogo.alt="c3d logo";
      drawerLogo.className = "logo opacity";
  
      let drawerLogoSmall = document.createElement("img");
      drawerLogoSmall.src="./media/c3dlogo_small.png";
      drawerLogoSmall.alt="c3d logo";
      drawerLogoSmall.className = "logo logo-small opacity";
      
      let drawerLogoContainer = document.createElement("div");
       drawerLogoContainer.className = "logo-container";
       drawerLogoContainer.appendChild(drawerLogo);
       drawerLogoContainer.appendChild(drawerLogoSmall);

      submenuContainer.appendChild(drawerLogoContainer); 
      submenuContainer.appendChild(submenuList);

      let hideSubmenuBtn = document.createElement('span');
      hideSubmenuBtn.className = "submenu-hide-btn";
      hideSubmenuBtn.innerHTML =  `<img src="./media/arrow-right.svg" alt="arrow-right">`;
      hideSubmenuBtn.addEventListener('click',()=>{
         let submenu = document.getElementById('manage-submenu');
         submenu.className = "navigation-submenu";
         let showSubmenuBtn = document.querySelector('#manage-submenu-show-btn');
         showSubmenuBtn.className = "submenu-show-btn";
         

         let allManageSection = document.querySelectorAll('.manage-section');
         //Change section grid column
         for(let i =0;i<allManageSection.length;i++)
         {
          allManageSection[i].classList.add("without-submenu");
        
         }



      });

      submenuContainer.appendChild(hideSubmenuBtn);

      let showSubmenuBtn = document.createElement('span');
      showSubmenuBtn.className = "submenu-show-btn hide";
      showSubmenuBtn.innerHTML =  `<img src="./media/arrow-right.svg" alt="arrow-right">`;
      showSubmenuBtn.id = "manage-submenu-show-btn";

      showSubmenuBtn.addEventListener('click',()=>{
            let submenu = document.getElementById('manage-submenu');
            submenu.className="navigation-submenu active-submenu";
            showSubmenuBtn.className = "submenu-show-btn hide";

            let allManageSection = document.querySelectorAll('.manage-section');
            //Change section grid column
            for(let i =0;i<allManageSection.length;i++)
            {
              allManageSection[i].classList.remove("without-submenu");
             
            }
        });

      this.parent.appendChild(showSubmenuBtn);

      this.parent.appendChild(submenuContainer);
    }

    //Insert menu item into submenu
    insertMenuItem(title,sectionId,isDefaultSection){
      let manageSubmenuList = document.getElementById("manage-submenu-list");
        let outputListItem = document.createElement('li');
        outputListItem.setAttribute("section-id",sectionId);

        outputListItem.addEventListener('click',e=>{
          let allManageSubmenu = document.querySelectorAll('#manage-submenu-list .list-item');
          for(let i=0;i<allManageSubmenu.length;i++)
          {
            allManageSubmenu[i].className = "list-item";
          }
         outputListItem.className = "list-item active";

         let targetSectionId = outputListItem.getAttribute("section-id");
         let activeSection = document.getElementById(targetSectionId);
         let allResourceSection = document.querySelectorAll('.manage-section');
         //Clear all active section
         for(let i =0;i<allResourceSection.length;i++)
         {
           allResourceSection[i].className = "manage-section container";
         }

         activeSection.className = "manage-section active-section container";

    

        });
         if(isDefaultSection)
         {
          outputListItem.className = "list-item active";
         }
         else{
          outputListItem.className = "list-item";
         }

         let listItemText  = document.createElement("span");
         listItemText.setAttribute("section-id",sectionId);
         listItemText.innerText = title;

         outputListItem.appendChild(listItemText);

         manageSubmenuList.appendChild(outputListItem);
    }

    

    
  
  }
  
  module.exports = ManageMenu;
  