
const origin = window.location.hostname.split(".")[0]
let fetchUrl = "https://classroomconfig.c3d.io"


const fetchConfig = () => {
  return new Promise(async (res, rej) => {
    await fetch(`${fetchUrl}/${origin}.json`)
    .then(res => res.json())
    .then(data => {
      console.log(data)
      let processLessonPlan = []

      data.lessonPlans && data.lessonPlans.forEach(element => {
        element.thumbnailURL = `${fetchUrl}/${element.thumbnailURL}` 
        processLessonPlan.push(element)
      });

      res({
        origin: origin,
        account: data.account,
        displayName: data.display,
        classLimit: data.classLimit,
        maxClasses: data.maxClasses,
        maxAssignments: data.maxAssignments,
        lessonPlans: processLessonPlan,
        // isLocal: window.location.host.includes('myc3d.test') || window.location.host.includes('localhost') ? true : false,
      })
    })
  })
  
}

module.exports = fetchConfig