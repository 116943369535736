let AbstractComponent = require('../AbstractComponent.js');
const { createElementWithAttribute } = require('../../utils/functions');
let shortid = require('shortid');

class Profiles extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = elem;

    this.menus = [];
    this.error = false;
    this.init();
    this.classInfo;
  }

  init() {
    this.createClassList();
    this.getClassesList();
    this.createAddStudentForm();
    this.createStudentListContainer();
    // listen to class changes on selector
    document.addEventListener('change', () => {
      this.currentClassId = window.windowClassId ? window.windowClassId : null;
      this.getClassesList().then(() => {
        this.updateStudentList(this.currentClassId);
      });
      document.getElementById('assignment-select-class-name').value=this.currentClassId;
    });
  }

  createClassList() {
    let formContent = `
        <div class="form-group">
            <label class="manage-input-title" for="select-class-name">Select Class</label>
            <select name="select-class-name" id="form-select-class-name-profile" class="form-control">
            <option value="none">No class found, please create a class in Add Class</option>
            </select>
        </div>
        `;

    let addClassForm = document.createElement('form');
    addClassForm.innerHTML = formContent;
    addClassForm.action = '#';
    addClassForm.method = 'POST';

    this.parent.appendChild(addClassForm);

    //OnChange event for selector class
    const selectClassContainer = document.getElementById(
      'form-select-class-name-profile'
    );
    selectClassContainer.addEventListener('change', (e) => {
      const classId = selectClassContainer.value;
      window.windowClassId = classId;

      document.getElementById('error-message-add-student').innerText = '';

      //Update student list
      this.updateStudentList(classId);
    });
  }

  //Get Classes list
  async getClassesList() {

    let userId = firebase.auth().currentUser.uid;
    let that = this;

    const selectClassContainer = document.getElementById(
      'form-select-class-name-profile'
    );

    //Fetch Class list from firebase
    await database
      .ref('/profiles/' + userId + '/classes')
      .once('value')
      .then(function (snapshot) {

        let classInfo = [];        

        // test for 'deleted' classes (hidden from frontend)
        // do not consider for Class List or Class max limit those with attribute 'hide'
        for (let entry of Object.entries(snapshot.val())) {
          if (!entry[1].hide){
            classInfo.push(entry);
          }
        }

        if (classInfo.length > 0) {

          const currentClassId = window.windowClassId ? window.windowClassId : null;

          that.classInfo = classInfo;

          //Output HTML element for select list
          let selectOptions = '';

          //classes in reverse order, latest on top
          classInfo.reverse().forEach((singleClass) => {
            let classId = singleClass[0];
            let className = singleClass[1].name;

            selectOptions += `<option ${currentClassId === classId ? "selected" : ""} value=${classId} >${className}</option>`;
          });

          selectClassContainer.innerHTML = selectOptions;

          //Update student list
          that.updateStudentList(selectClassContainer.value);
        }
      });
  }

  createStudentListContainer() {
    const studentListTitle = createElementWithAttribute('h3', {
      class: 'manage-student-list-title',
    });

    studentListTitle.innerText = 'Student List';

    this.parent.appendChild(studentListTitle);

    const studentProfileListContainer = createElementWithAttribute('div', {
      id: 'student-profile-list-container',
    });

    const studentProfileList = createElementWithAttribute('ul', {
      id: 'student-profile-list',
      class: 'list-group',
    });

    studentProfileListContainer.appendChild(studentProfileList);

    this.parent.appendChild(studentProfileListContainer);

    const noStudentInListMessage = createElementWithAttribute('p', {
      class: 'no-student-list-message hide',
    });
    noStudentInListMessage.innerText = 'There is no student in this class.';
    this.parent.appendChild(noStudentInListMessage);
  }

  updateStudentList(classId) {
    //Get student list base on class id
    let userId = firebase.auth().currentUser.uid;
    let that = this;

    //Fetch Class list from firebase
    database
      .ref('/profiles/' + userId + '/classes/' + classId + '/student')
      .once('value')
      .then(function (snapshot) {
        const studentList = document.getElementById('student-profile-list');

        studentList.innerHTML = '';

        let students = snapshot.val();

        if (students) {
          //Save students to the current student list fetched
          console.log("students", students)
          const classList =  that.classInfo.filter(className => className[0] === classId)

          classList[0] ? classList[0][1].student = students : ""

          console.log("Update student list", classList)
          //Have some student, hiding the no student list message
          document
            .querySelector('.no-student-list-message')
            .classList.add('hide');
          const studentInfoDataset = Object.entries(students);

          studentInfoDataset.forEach((student) => {
            const p = createElementWithAttribute('li', {
              class: 'list-group-item single-student',
              'student-id': student[0],
            });

            const studentInfo = createElementWithAttribute('div', {
              class: 'student-info',
            });
            studentInfo.innerHTML = `<span class="student-name">${student[1].name}</span>`;

            const editBtn = createElementWithAttribute('button', {
              class: 'edit-student-btn',
              'student-id': student[0],
              'student-name': student[1].name,
            });
            editBtn.innerText = 'Edit';

            editBtn.addEventListener('click', (e) => {
              const parentContainer = e.target.parentNode.parentNode;
              parentContainer.classList.add('hide');

              let studentId = e.target.getAttribute('student-id');
              let studentName = e.target.getAttribute('student-name');
              editStudentProfile(p, userId, classId, studentId, studentName);
            });

            const deleteBtn = createElementWithAttribute('button', {
              class: 'delete-student-btn',
              'student-id': student[0],
              'student-name': student[1].name,
            });

            deleteBtn.innerText = 'Delete';
            deleteBtn.addEventListener('click', (e) => {
              let studentId = e.target.getAttribute('student-id');
              let studentName = e.target.getAttribute('student-name');

              let r = confirm(`Are you sure to delete ${studentName}?`);
              if (r == true) {
                const userRef = database.ref('/users/' + studentId);

                userRef.on('value', (snapshot) => {
                  if (snapshot.val()) {
                    const worlds = Object.keys(snapshot.val());

                    worlds.forEach((world) => {
                      database.ref(`/temp/${world}`).remove();
                    });
                  }
                });

                userRef.remove();
                database
                  .ref(
                    '/profiles/' +
                      userId +
                      '/classes/' +
                      classId +
                      '/student/' +
                      studentId
                  )
                  .remove();
                database.ref('/progress/' + studentId).remove();

                that.updateStudentList(classId);

                that.updateStudentListProgress(classId);
              }
            });

            const btnContainer = createElementWithAttribute('span', {
              class: 'btn-container',
            });

            btnContainer.appendChild(editBtn);
            btnContainer.appendChild(deleteBtn);

            studentInfo.appendChild(btnContainer);

            p.appendChild(studentInfo);

            studentList.appendChild(p);

            //Open edit student form
            function editStudentProfile(
              parentElement,
              userId,
              classId,
              studentId,
              studentName
            ) {
              const editForm = createElementWithAttribute('div', {
                class: 'edit-profile-form',
              });
              editForm.innerHTML = `<input class="form-control" id="form-change-name-input" type="text" value="${studentName}" />`;

              const saveBtn = createElementWithAttribute('button', {
                class: 'save-btn',
              });
              saveBtn.innerText = 'Save';
              saveBtn.addEventListener('click', (e) => {
                let inputName = e.target.parentNode.parentNode
                  .querySelector('#form-change-name-input')
                  .value.trim();
                if (inputName && inputName.length > 1) {
                  //Update student name
                  let updates = {};
                  updates[
                    '/profiles/' +
                      userId +
                      '/classes/' +
                      classId +
                      '/student/' +
                      studentId
                  ] = { name: inputName };

                  database.ref().update(updates);

                  const listElement = e.target.parentNode.parentNode.parentNode;
                  //Remove edit form
                  listElement.removeChild(e.target.parentNode.parentNode);
                  //Display student info
                  const studentInfo = listElement.querySelector(
                    '.student-info'
                  );
                  studentInfo.classList.remove('hide');
                  //Update student new name in student list
                  studentInfo.querySelector(
                    '.student-name'
                  ).innerText = inputName;
                } else {
                  const errorMsg = document.querySelector(
                    '.edit-form-error-message'
                  );
                  errorMsg.innerText = 'Please input valid student name.';
                  errorMsg.className = 'edit-form-error-message text-red';
                }
              });

              const cancelBtn = document.createElement('button');
              cancelBtn.className = 'cancel-btn';
              cancelBtn.innerText = 'Cancel';
              //Cancel edit form
              cancelBtn.addEventListener('click', (e) => {
                const listElement = e.target.parentNode.parentNode.parentNode;
                //Remove edit form
                listElement.removeChild(e.target.parentNode.parentNode);
                //Display student info
                const studentInfo = listElement.querySelector('.student-info');
                studentInfo.classList.remove('hide');
              });

              const editFormBtnContainer = document.createElement('div');
              editFormBtnContainer.appendChild(saveBtn);
              editFormBtnContainer.appendChild(cancelBtn);
              editForm.appendChild(editFormBtnContainer);

              const errorMsg = document.createElement('p');
              errorMsg.innerText = '';
              errorMsg.className = 'edit-form-error-message text-red hide';
              editForm.appendChild(errorMsg);

              parentElement.appendChild(editForm);
            }
          });
        } else {
          document
            .querySelector('.no-student-list-message')
            .classList.remove('hide');
        }
      });
  }

  //Update progress view
  updateStudentListProgress(classId) {
    let userId = firebase.auth().currentUser.uid;
    database
      .ref('/profiles/' + userId + '/classes/' + classId + '/student')
      .once('value')
      .then(function (snapshot) {
        let students = snapshot.val();
        if (students) {
          const studentInfoDataset = Object.entries(students);
          const studentInfo = studentInfoDataset.map((student) => {
            return {
              studentId: student[0],
              name: student[1].name,
            };
          });

          //Get student progress from firebase
          const allStudentInfo = studentInfo.map((student) => {
            return new Promise((resolve, reject) => {
              database
                .ref('/progress/' + student.studentId)
                .once('value')
                .then(function (snapshot) {
                  let output = `<tr><td>${student.name}</td>`;

                  const progressInfo = snapshot.val()
                    ? Object.entries(snapshot.val())
                    : undefined;
                  if (progressInfo) {
                    progressInfo.forEach((singleModule) => {
                      //Calculate progress based on step/Total step
                      let currentProgress = (
                        (singleModule[1].step /
                          moduleSteps[`${singleModule[0]}`]) *
                        100
                      ).toFixed(0);
                      switch (true) {
                        case currentProgress >= 1 && currentProgress <= 50:
                          output += `<td class="progress-status red">`;
                          break;

                        case currentProgress > 50 && currentProgress < 100:
                          output += `<td class="progress-status yellow">`;
                          break;

                        case currentProgress == 100:
                          output += `<td class="progress-status green">`;
                          break;

                        default:
                          output += `<td class="progress-status grey">`;
                          break;
                      }
                      output += `<a class="workplace-link" target="_blank" href="${mainApp}/?origin=${classroomOrigin.origin}&user=${student.studentId}&name=${student.name}&module=${singleModule[0]}"><span class="progress-bar"></span><span class="progress-text">${currentProgress}%</span></a></td>`;
                    });
                  } else {
                    //Default progress to 0
                    let currentProgress = 0;
                    [
                      'module1',
                      'module2',
                      'module3',
                      'module4',
                      'module5',
                      'module6',
                      'module7',
                      'module8',
                      'module9',
                    ].forEach((singleModule) => {
                      output += `<td class="progress-status grey">`;
                      output += `<a class="workplace-link" target="_blank" href="${mainApp}/?origin=${classroomOrigin.origin}&module=${singleModule}"><span class="progress-bar"></span><span class="progress-text">${currentProgress}%</span></a></td>`;
                    });
                  }

                  output += `</tr>`;
                  resolve(output);
                });
            });
          });

          Promise.all(allStudentInfo).then(function (AllStudentProgress) {
            //Append all students progress list to table
            let output = '';
            AllStudentProgress.forEach((studentItem) => {
              output += studentItem;
            });
            document.getElementById(
              'student-progress-tbody'
            ).innerHTML = output;
          });
        } else {
          //Nothing find, empty student progress table
          document.getElementById('student-progress-tbody').innerHTML = '';
        }
      });
  }

  //Add student to class method
  createAddStudentForm() {
    const attHeader = {
      class: 'manage-input-title',
      for: 'student-name',
    };

    const attInstruction = {
      style: 'display: block;',
    };

    const attTextArea = {
      rows: 4,
      class: 'form-control',
      name: 'student-name',
      id: 'input-student-name',
    };

    const attButton = {
      id: 'add-student-btn',
      title: 'button',
    };
    const errDiv = {
      id: 'error-message-add-student',
    };

    const stuTitle = createElementWithAttribute('h2', attHeader);
    stuTitle.innerHTML = 'Add New Student';

    const instruction = createElementWithAttribute('small', attInstruction);
    instruction.innerHTML = 'Enter student names separate by comma';

    this.stuTextArea = createElementWithAttribute('textarea', attTextArea);

    const stuButton = createElementWithAttribute('button', attButton);
    stuButton.innerHTML = 'Add';

    this.errDiv = createElementWithAttribute('div', errDiv);

    let addStudentForm = document.createElement('form');
    addStudentForm.addEventListener('submit', (e) => {
      e.preventDefault();
    });
    addStudentForm.className = 'add-student-container';

    addStudentForm.appendChild(stuTitle);
    addStudentForm.appendChild(instruction);
    addStudentForm.appendChild(this.stuTextArea);
    addStudentForm.appendChild(stuButton);
    addStudentForm.appendChild(this.errDiv);

    this.parent.appendChild(addStudentForm);

    stuButton.addEventListener('click', () => {
      this.getClassesList().then(() => {
        this.addStudentProfile();
      });      
    });

    // Execute a function when the user releases a key on the keyboard
    // stuTextArea.addEventListener("keyup", function (event) {
    //   // Number 13 is the "Enter" key on the keyboard
    //   if (event.keyCode === 13) {
    //     console.log("test")
    //     event.preventDefault();
    //     // Trigger the button element with a click
    //     stuButton.click();
    //   }
    // });
  }

  addStudentProfile() {
    let errorMsg = this.errDiv;
    errorMsg.innerText = '';
    errorMsg.classList.remove('show-error-message');
    errorMsg.classList.remove('text-green');

    let userId = firebase.auth().currentUser.uid;
    let classId = document.getElementById('form-select-class-name-profile')
      .value;
    if (classId == 'none') {
      errorMsg.innerText = `Require a class before add student.`;
      document.getElementById('input-student-name').value = '';
      errorMsg.classList.add('show-error-message');
      return;
    }

    const classList =  this.classInfo.filter(className => className[0] === classId)
    const classSize = classList[0] && classList[0][1].student ? Object.keys(classList[0][1].student).length : 0
    console.log("Class list", classList)
    console.log("Class list", classSize)

    try {
      const newStudentNames = this.stuTextArea.value.split(',');
      let errorMes;
      if (newStudentNames.length >= 1 && newStudentNames[0].trim() != '') {
        if(classroomOrigin.classLimit > 0) {
          if(newStudentNames.length + classSize > classroomOrigin.classLimit) {
            errorMsg.innerText = `Maximum class size is ${classroomOrigin.classLimit}. There is currently ${classSize} students in the class.`;
            errorMsg.classList.add('show-error-message');
            return;
          } else {
            newStudentNames.forEach((student) => {
              student = student.trim();
    
              if (student.length > 0) {
                let newProfile = database
                  .ref('/profiles/' + userId + '/classes/' + classId + '/student')
                  .push();
                newProfile.set(
                  {
                    name: student,
                  },
                  (error) => {
                    errorMes = error;
                  }
                );
              }
            });
          }
        } else {
          newStudentNames.forEach((student) => {
            student = student.trim();
  
            if (student.length > 0) {
              let newProfile = database
                .ref('/profiles/' + userId + '/classes/' + classId + '/student')
                .push();
              newProfile.set(
                {
                  name: student,
                },
                (error) => {
                  errorMes = error;
                }
              );
            }
          });
        }

        
      } else {
        errorMsg.innerText = `Could not add empty value into Class.`;
        errorMsg.classList.add('show-error-message');
        return;
      }
      if (!errorMes) {
        errorMsg.innerText = `Successfully added ${newStudentNames.length} students into the class.`;
        //if limited class size
        if (classroomOrigin.origin === 'classroom') {
          errorMsg.innerText += `
          Maximum class size is ${classroomOrigin.classLimit}.`;
        }        
        document.getElementById('input-student-name').value = '';
        errorMsg.classList.add('show-error-message');
        errorMsg.classList.add('text-green');

        this.updateStudentList(classId);
        this.updateStudentListProgress(classId);
      } else {
        console.log(errorMes);
        errorMsg.innerText = `Could not add ${newStudentNames} into Class.`;
        errorMsg.classList.add('show-error-message');
      }
    } catch (err) {
      console.log(err);
      errorMsg.innerText = 'Invalid input format.';
      errorMsg.classList.add('show-error-message');
    }
  }

  //Make fake progress in Module ---- For testing
  createProgress(studentId) {
    let moduleIdArray = [];
    for (let i = 0; i < 9; i++) {
      moduleIdArray.push(shortid.generate());
    }

    database.ref('/progress/' + studentId).set(
      {
        module1: {
          lesson: 'Lesson1',
          sid: moduleIdArray[0],
          step: 0,
        },
        module2: {
          lesson: 'Lesson2',
          sid: moduleIdArray[1],
          step: 0,
        },
        module3: {
          lesson: 'Lesson3',
          sid: moduleIdArray[2],
          step: 0,
        },
        module4: {
          lesson: 'Lesson4',
          sid: moduleIdArray[3],
          step: 0,
        },
        module5: {
          lesson: 'Lesson5',
          sid: moduleIdArray[4],
          step: 0,
        },
        module6: {
          lesson: 'Lesson6',
          sid: moduleIdArray[5],
          step: 0,
        },
        module7: {
          lesson: 'Lesson7',
          sid: moduleIdArray[6],
          step: 0,
        },
        module8: {
          lesson: 'Lesson8',
          sid: moduleIdArray[6],
          step: 0,
        },
        module9: {
          lesson: 'Lesson9',
          sid: moduleIdArray[6],
          step: 0,
        },
      },
      function (error) {
        if (error) {
          console.log(error);
        } else {
          //Update users cluster in firebase ---- For testing
          // database.ref('/users/' + studentId).set({
          //     world1: moduleIdArray[0]+" - world1",
          //     world2: moduleIdArray[1]+" - world2",
          //     world3: moduleIdArray[2]+" - world3",
          //     world4: moduleIdArray[3]+" - world4",
          //     world5: moduleIdArray[4]+" - world5",
          //     world6: moduleIdArray[5]+" - world6",
          //     world7: moduleIdArray[6]+" - world7",
          //     world8: moduleIdArray[7]+" - world8",
          //     world9: moduleIdArray[8]+" - world9",

          // }

          // );

          database.ref('/users/' + studentId).set({
            [moduleIdArray[0]]: 'module1',
            [moduleIdArray[1]]: 'module2',
            [moduleIdArray[2]]: 'module3',
            [moduleIdArray[3]]: 'module4',
            [moduleIdArray[4]]: 'module5',
            [moduleIdArray[5]]: 'module6',
            [moduleIdArray[6]]: 'module7',
            [moduleIdArray[7]]: 'module8',
            [moduleIdArray[8]]: 'module9',
          });
        }
      }
    );
  }
}

module.exports = Profiles;
