let AbstractComponent = require('../AbstractComponent.js');

class Assignments extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = document.querySelector(`.${elem.className}`);

    this.uid = localStorage.getItem('uid');
    this.studentId = getQueryVariable('student');
    this.studentName = getQueryVariable('name');
    this.classId = getQueryVariable('class');

    this.assignments = [];
  }

  //Get Assignments
  getAssignments() {
    const assignmentFolderUrl = `/profiles/${this.uid}/classes/${this.classId}/assignment`;

    this.fetchData(assignmentFolderUrl).then((result) => {
      if (result.status === 'OK') {

        // test for 'deleted' assignments (hidden from frontend)
        // do not consider for rendering those with attribute 'hide')
        for (let entry of Object.entries(result.data)) {
          if (!entry[1][1].hide){
            this.assignments.push(entry[1]);
          }
        }

        result.data.forEach((anAssignment, index) => {
          const studentAssignmentUrl = `/profiles/${this.uid}/classes/${this.classId}/student/${this.studentId}/assignments/${anAssignment[0]}`;

          this.fetchData(studentAssignmentUrl).then((data) => {
            if (data.result === 'OK') {
              this.assignments[index].worldId = result;
            }
          });
        });

        this.renderAssignmentToPage(result.data);
      }
    });
  }

  //Render assignment to portal page
  renderAssignmentToPage() {
    let assignmentHeader = document.createElement('h2');
    assignmentHeader.innerHTML = 'Projects';
    assignmentHeader.className = 'col-12 assignment-list-header';

    let assignmentContainer = document.createElement('div');
    assignmentContainer.className = 'container assignments-container';

    let assignmentList = document.createElement('ul');
    assignmentList.className = 'assignment-list row';

    assignmentContainer.appendChild(assignmentHeader);
    assignmentContainer.appendChild(assignmentList);

    this.parent.appendChild(assignmentContainer);
    console.log(this.parent);
    //Render single assignment detail
    let listItemOutput = '';

    this.assignments.forEach((assignment) => {
      
      listItemOutput += `
      <li class="list-item col-12 col-md-4">
        <div class="module-link">
          <div class="item-header">
            <h3 class="assignment-title">${assignment[1].name}</h3>
          </div>
          <div class="assignment-description">
            ${assignment[1].description}
          </div>
          <div class="go-to">
            <a href="${mainApp}/?origin=${classroomOrigin.origin}&class=${this.classId}&student=${this.studentId}&name=${this.studentName}&assignment=${assignment[0]}&role=student"> Go </a> 
          </div>
        </div>
      </li>\n`;
      // console.log("output", assignment, listItemOutput)

    });
    console.log(listItemOutput);
    assignmentList.innerHTML = listItemOutput;
  }

  fetchData(url) {
    return new Promise((resolve, reject) => {
      database
        .ref(url)
        .once('value')
        .then(function (snapshot) {
          let result = snapshot.val();
          if (result) {
            const data = Object.entries(result);

            resolve({ data, status: 'OK' });
          } else {
            resolve({ data: null, status: 'ERROR' });
          }
        });
    });
  }
}

module.exports = Assignments;
