let AbstractComponent = require("../AbstractComponent.js");

class Profile extends AbstractComponent{
  
	constructor(elem) {
    super(elem);
	  this.userId = firebase.auth().currentUser.uid;

    this.renderProfiles.call(this);
	}

  async renderProfiles() {
    // populate html element
    let profilesDiv = this.elem.querySelector('#student-profile-list');
    profilesDiv.innerHTML = '';
    
    let profiles = await this.getProfiles();
    Object.keys(profiles).forEach( k => {
      let p = document.createElement('li');
      p.className = "list-group-item";
      
      p.innerText = profiles[k].name;
      p.addEventListener('click', async evt => {
        // choosing a profile will retrieve a list of worlds under the profile
        this.selectProfile(k);
        localStorage.setItem('profileId', k);        
        let worlds = await this.getWorlds();
        this.renderWorldsSelection.call(this, worlds);        
      });

      profilesDiv.append(p);
    });
    // profilesDiv.append(this.createAddButton( e => {
    //   let profileName = prompt('Please enter the new profile name');
    //   if (profileName) {
    //     this.addProfile(profileName);
    //     this.renderProfiles();
    //   }
    // }));
  }

  renderWorldsSelection(worlds) {
    let worldsDiv = this.elem.querySelector('.worlds');
    worldsDiv.innerHTML = '';
    
    let worldIds = worlds ? Object.keys(worlds) : []
    
    worldIds.forEach( w => {
      let b = document.createElement('button');
      b.innerHTML = worlds[w];
      b.addEventListener( 'click', evt => {
        console.log(`selecing ${w}, ${worlds[w]}`);
        window.location.href = `${window.location.origin}/?id=${w}`;
      });
      worldsDiv.append(b);
    });

    worldsDiv.append(this.createAddButton( async evt => {
      let worldName = prompt('Please enter world name');
      if (worldName) {
        this.addWorld(worldName);
        let worlds = await this.getWorlds();
        this.renderWorldsSelection(worlds);
      }
    }));                    
  }

  createAddButton(event) {
    let addButton = document.createElement('button');
    addButton.innerHTML = '+';
    addButton.addEventListener('click', event);
    return addButton;
  }
  
	getProfiles() {
	  return new Promise( (resolve, reject) => {
	    database.ref('/profiles/' + this.userId).once('value').then(snapshot => {
	      let profiles = snapshot.val();
	      profiles[this.userId] = { name: 'default' };
	      resolve(profiles);
	    }).catch (err => {
	      reject(err);
	    });
	  });
	}

	addProfile(profileName) {
	  let newProfile = database.ref('/profiles/' + this.userId).push();
	  newProfile.set({
	    name: profileName
	  });
	}

	selectProfile(profileId) {
	  this.currentProfile = profileId;
	}

	// retrieve a list of worlds under the current profile
	getWorlds() {
	  return new Promise( (resolve, reject) => {
	    if (!this.currentProfile) {
	      reject(new Error('a profile must be selected'));
	    } else {	      
	      // read all the users
	      database.ref('/users/' + this.currentProfile).once('value').then( snapshot => {	      
		      resolve(snapshot.val());
	      });
	    }
	  });	  
	}

	addWorld(worldName) {
	  return new Promise( (resolve, reject) => {
	    if (!this.currentProfile) {
	      reject(new Error('a profile must be selected'));
	    } else {
	    
	      // create a short id and add to the current world
	      let worldId = shortid.generate();
	      
	      let w = database.ref('/users/' + this.currentProfile + '/' + worldId).set(worldName);
	      resolve(w);
	    }	    
	  });
  }

}

module.exports = Profile;
