let AbstractComponent = require('../AbstractComponent.js');

class Welcome extends AbstractComponent {
  constructor(elem) {
    super(elem)
    this.setupElement()
  }

  setupElement() {
    const studentId = getQueryVariable('student');
    const classId = getQueryVariable('class');
    const name = getQueryVariable('name');

    if (!studentId || !name) {
      let errMsg = document.createElement('h3');
      errMsg.innerHTML = `<p>Invalid id or name, unable to get lessons.</p>
      <a href="entrance.html">Back to entrance</a>
      `;
      errMsg.className = 'error-message';
      this.parent.appendChild(errMsg);
      return;
    }

    let welcomeContainer = document.createElement('div');
    welcomeContainer.setAttribute('class', 'welcome-container');
    this.elem.appendChild(welcomeContainer);

    let goBackBtn = document.createElement('button');
    goBackBtn.innerHTML = '< Go Back';
    goBackBtn.setAttribute('id', 'go-back-btn');
    goBackBtn.addEventListener('click', (e) => {
      console.log('goback')
      window.location = `${window.location.origin}/entrance.html`;
    });

    welcomeContainer.appendChild(goBackBtn);

    let welcomeMsg = document.createElement('h2');
    welcomeMsg.innerHTML = `Hello, ${name.replace(/%20/g, " ")}. Welcome to c3d.`;
    welcomeMsg.className = 'welcome-msg';
    welcomeContainer.appendChild(welcomeMsg);
  }
}

module.exports = Welcome